import logo from './logo.svg';
import './App.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC2r5dBWWGPeiPO4PfZhAC9kK8HPO5wHic",
  authDomain: "tthankdt-portfolio.firebaseapp.com",
  projectId: "tthankdt-portfolio",
  storageBucket: "tthankdt-portfolio.appspot.com",
  messagingSenderId: "131429746820",
  appId: "1:131429746820:web:70a9840714856ffc9c9ad1",
  measurementId: "G-94P692B019"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
